<template>
  <Modal withdrawn-modal theme="dark" @close="$emit('close')">
    <h2>
      <svg-fail />
    </h2>
    <p>{{ $t('_.withdrawnAccount') }}</p>
    <p>{{ $t('_.signupAsNewAccount') }}</p>
    <ColorButton type="red" @click="$emit('close')">{{ $t('confirm') }}</ColorButton>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import SvgFail from '@shared/graphics/svg-fail.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'WithdrawnModal',
  lexicon: 'shared.member.login',
  props: {
    options: { type: Object },
  },
  components: { ColorButton, SvgFail, Modal },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[withdrawn-modal] { .tc;
  > .panel {
    > .header { .pb(70); }
    h2 {
      svg { .wh(24); .block; .mh-c; .mb(16);
        .fill-target { fill: #e5bb30; }
      }
    }
    h2 + p { .c(#e5bb30); .mb(20); }
    p + p { .c(@c-w08); }
    [color-button] { .w(310); .mv(100); }
  }
}
</style>
